import { urlRs } from "~/utils/url";

export function Legals(props: { function: string }) {
  return (
    <div class="legals-content opened" data-test="legals-form">
      <p>
        Les données signalées ci-dessus par un astérisque sont nécessaires pour
        nous permettre de répondre à votre demande {props.function}. Elles sont
        collectées et traitées informatiquement par COVALENS (société du groupe
        ALTAREA). Pour en savoir plus sur le traitement de vos données et vos
        droits, consultez{" "}
        <a href={urlRs("pages", "/donnees-personnelles.html")}>
          <strong>notre politique de protection des données</strong>
        </a>
        .
      </p>
    </div>
  );
}
